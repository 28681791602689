
import { defineComponent, ref, computed, onMounted } from 'vue';
import { Field } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import { ElNotification } from "element-plus";
import { hideModal } from "@/core/helpers/dom";
import * as Yup from "yup";
import AddApiEndPointModule from "@/components/modals/forms/AddApiEndPointModule.vue";

export default defineComponent({
    name: "onBoardManufacturer",
    components: {
        Field,
        AddApiEndPointModule
    },
    props: {

    },
    data() {
        return {
            showTokenError: false,
            showErrorApi: false,
            errors: [],
            manufacturerDetails: {} as any,
            productList: [] as any,
            manufacturer: [] as any,
            consumer: [] as any,
            useMCPTData: [] as any,
            useGroupData: [] as any,
            useServiceData: [] as any,
            useOrganizationType: [] as any,
            apiProvider: [] as any,
            header: {} as any,
            body: {} as any,
            form_data: {} as any,
            queryparam: {} as any,
            headerInput: [{
                name: '',
                value: ''
            }],
            bodyInputs: [
                {
                    name: '',
                    value: ''
                }],
            formDataInputs: [
                {
                    name: '',
                    value: ''
                }],
            queryinputs: [
                {
                    name: '',
                    value: ''
                }
            ],
            currentIndex: 0,
            tabs: [
                { title: 'Form View'},
                { title: 'JSON View '},
            ],
            jsonString: '{"manufacturerCode":"","consumerCode":"","providerId":""}',
            jsonObject: {},
            api_list: [{"apiEndpointUrl":"http://no_url.com", "apiEndpointUrlMethod": "GET"}, {"apiEndpointUrl": "http://dummy.com", "apiEndpointUrlMethod": "GET"}],
            apiGroupList: [],
            categoryList: []
        }

    },
    
    setup(props) {
        const onBoardManufacturerModalRef = ref<null | HTMLElement>(null);
        const formData = ref<any>({
            manufacturerCode: "",
            credentialOrgType: "",
            apiProviderOrg: "",
            serviceName: "",
            category: "",
            productGroupId: "",
            consumerCode: "",
            productId: "",
            operationId: "",
            version: "",
            authenticationType: "",
            tokenValidity: "",
            prefix: ""

        });

        const apiEndpoint = ref<any>({
            url: "",
            queryParams: "false",
            verbId: "POST",
            webhookSchedulerAPIs: ""
        });

        const tokenEndpoint = ref<any>({
            url: "",
            queryParams: "false",
            verbId: "",
            webhookSchedulerAPIs: ""
        });
    // Reference to the contenteditable div
    const jsonEditor = ref<HTMLElement | null>(null);
    const combinedData = computed(() => {
        return JSON.stringify({
            manufacturerCode: formData.value.manufacturerCode,
            consumerCode: formData.value.consumerCode,
            credentialOrgType: formData.value.credentialOrgType,
            apiProviderOrg: formData.value.apiProviderOrg,
            serviceName: formData.value.serviceName,
            category: formData.value.category,
            productGroupId: formData.value.productGroupId,
            productId: formData.value.productId,
            operationId: formData.value.operationId,
            version: formData.value.version,
            authenticationType: formData.value.authenticationType,
            tokenValidity: formData.value.tokenValidity,
            credentials: {
                prefix: formData.value.prefix,
                form_data: {},
                body: {},
                header: {},
                query_params: {}
            },
            apiEndpoint: apiEndpoint.value,
            tokenEndpoint: tokenEndpoint.value
        }, null, 2);
    });
    
    // Initialize the contenteditable div with pretty-printed JSON
    onMounted(() => {
      if (jsonEditor.value) {
        jsonEditor.value.textContent = combinedData.value;
      }
    });
        return {
            jsonEditor,
            formData,
            tokenEndpoint,
            apiEndpoint,
            onBoardManufacturerModalRef,
        }
    },
    methods: {
        handleapiEndpointChange(api_url, url_param){
            const selectedItem = this.api_list.find(item => item.apiEndpointUrl === api_url);
            // console.log("api_url-->>", api_url);
            // console.log("selectedItem-->>", selectedItem);
            if (selectedItem){
                if (url_param=="api"){
                    this.apiEndpoint.verbId = selectedItem.apiEndpointUrlMethod
                }else{
                    this.tokenEndpoint.verbId = selectedItem.apiEndpointUrlMethod
                }
                // console.log("..")
            }
        },
        resetAPI(event){
            // console.log("inside reset API now we can call all APIs", event);
            if (event === "true") {
                this.api_list = [];

                const codesToHandle = [
                    this.formData.manufacturerCode,
                    this.formData.consumerCode,
                    this.formData.apiProviderOrg,
                ];

                codesToHandle.forEach((code) => {
                    if (code) {
                        this.handleOrganizationChange(code);
                    }
                });
            }
        },
        getMasterData2() {
            ApiService.get("/master?organizationId=1SB")
                .then((data) => {
                    const { APIGroup } = data.data.data
                    const { categoryList } = data.data.data
                    this.apiGroupList = APIGroup;
                    this.categoryList = categoryList

                })
                .catch((error) => {
                    console.log("Error", error)
                })
        },
       
        addApiEndPointMethod() {
            // console.log("addApiEndPointMethod callupdate apigrouplist--->>", this.apiGroupList);
            // console.log("--organziations list-->>", this.apiProvider);
            (this.$refs["addEndpointForm"] as any).callUpdate(this.apiGroupList, this.apiProvider);
        },
        handleOrganizationChange(organizationCode) {
            // console.log("organization code", organizationCode);
            ApiService.get(`/organizations/${organizationCode}/apis?featureMode=latest_endpoints`)
            .then((data) => {
                // console.log("organiztion data.data.data.api_list-->>", data.data.data.api_list);
                this.api_list = this.api_list.concat(data.data.data.api_list)
                // console.log("updated api_list", this.api_list);
            })
            .catch((error) => {
                console.error("Errro while on change fetching organizatin apis", error);
            });
        },
        handleClick(index) {
            // console.log("index --,<", index, "- --", event);
            this.currentIndex = index; // Prevent the default form submit behavior
          
          
        },
        convert_object_to_array(credetial_field) {
            //console.log("here with value", credetial_field)
            let arr = [];
            for (let key in credetial_field){
                arr.push({"name": key,  "value": credetial_field[key]})
            }
            return arr;
        },
        onInput(event) {
            this.errors.splice(0, this.errors.length)
            if (event.target.textContent) {
                try{
                    let content = JSON.parse(event.target.textContent|| '{}');
                    let body_arr = this.convert_object_to_array(content.credentials.body);
                    if (body_arr.length != 0){
                        //console.log("body_arr-->>", body_arr);
                        this.bodyInputs = body_arr;
                    }
                    let query_params_arr = this.convert_object_to_array(content.credentials.query_params);
                    //console.log("query_params_arr-->>", query_params_arr);
                    if (query_params_arr.length != 0){
                        
                        this.queryinputs = query_params_arr;
                    }
                    let header_arr = this.convert_object_to_array(content.credentials.header);
                    if (header_arr.length != 0){
                        //console.log("header_arr-->>", header_arr);
                        this.headerInput = header_arr;
                    }
                    let form_data_arr = this.convert_object_to_array(content.credentials.form_data);
                    if (form_data_arr .length != 0){
                        //console.log("form_data_arr-->>", form_data_arr);
                        this.formDataInputs = form_data_arr;
                    }
        
                    try {
                        //Object.assign(this.formData.value, { manufacturerCode: content.manufacturerCode });
                        if (this.formData.manufacturerCode != content.manufacturerCode){
                            this.handleOrganizationChange(content.manufacturerCode);
                        }
                        if (this.formData.apiProviderOrg != content.apiProviderOrg){
                            this.handleOrganizationChange(content.apiProviderOrg);
                        }
                        if (this.formData.consumerCode != content.consumerCode){
                            this.handleOrganizationChange(content.consumerCode);
                        }
                        this.formData.manufacturerCode = content.manufacturerCode;
                        this.formData.credentialOrgType = content.credentialOrgType;
                        this.formData.apiProviderOrg = content.apiProviderOrg;
                        this.formData.serviceName = content.serviceName;
                        this.formData.category = content.category;
                        this.formData.productGroupId = content.productGroupId;
                        this.formData.consumerCode = content.consumerCode;
                        this.formData.productId = content.productId;
                        this.formData.operationId = content.operationId;
                        this.formData.version = content.version;
                        this.formData.authenticationType = content.authenticationType;
                        this.formData.tokenValidity = content.tokenValidity;
                        this.formData.prefix = content.credentials.prefix || "";
                        if ("apiEndpoint" in content){
                            if ("url" in content.apiEndpoint){
                                this.apiEndpoint.url = content.apiEndpoint.url;
                            }
                            if ("verbId" in content.apiEndpoint){
                                this.apiEndpoint.verbId = content.apiEndpoint.verbId;
                            }
                        }
                        if ("tokenEndpoint" in content){
                            if ("url" in content.tokenEndpoint){
                                this.tokenEndpoint.url = content.tokenEndpoint.url;
                            }
                            if ("verbId" in content.tokenEndpoint ){
                                this.tokenEndpoint.verbId = content.tokenEndpoint.verbId;
                            }
                        }
                        //console.log("AFTER2 this.apiEndpoint-->>", this.apiEndpoint)
                        //console.log("AFTER2 content.apiEndpoint-->>", content.apiEndpoint)
                    } catch (error) {
                        console.error('Invalid JSON input:', error);
                        this.errors.push("Please enter valid JSON input");
                    // Optionally handle invalid JSON input
                    }
                }catch(error){
                    console.error("Invalid JSON input:", error);
                    this.errors.push("Please enter valid JSON input");
                }
            }
        },
        getCombinedData() {
            console.log("this.formData-- in getcombinedData-->>", this.formData);
            const body = this.objectMaker(this.bodyInputs);
            const header = this.objectMaker(this.headerInput);
            const queryParam = this.objectMaker(this.queryinputs);
            const formdata = this.objectMaker(this.formDataInputs);
            console.log("body-->", body, "--header-->", header, "--queryParam-->", queryParam, "--formData-->", formdata);
            //this.validateForm()
            return JSON.stringify({
              manufacturerCode: this.formData.manufacturerCode,
              consumerCode: this.formData.consumerCode,
              credentialOrgType: this.formData.credentialOrgType,
              apiProviderOrg: this.formData.apiProviderOrg,
              serviceName: this.formData.serviceName,
              category: this.formData.category,
              productGroupId: this.formData.productGroupId,
              productId: this.formData.productId,
              operationId: this.formData.operationId,
              version: this.formData.version,
              authenticationType: this.formData.authenticationType,
              tokenValidity: this.formData.tokenValidity,
              apiEndpoint: this.apiEndpoint,
              tokenEndpoint: this.tokenEndpoint,
              credentials:{
                prefix: this.formData.prefix,
                body: body,
                header: header,
                query_params: queryParam,
                form_data: formdata
              }
            }, null, 2);
        },
        //removeField on close Module
        removeFields() {

            this.formData.manufacturerCode = "",
                this.formData.credentialOrgType = "",
                this.formData.apiProviderOrg = "",
                this.formData.serviceName = "",
                this.formData.category = "",
                this.formData.productGroupId = "",
                this.formData.consumerCode = "",
                this.formData.productId = "",
                this.formData.operationId = "",
                this.formData.version = "",
                this.formData.authenticationType = "",
                this.formData.tokenValidity = "",
                this.formData.prefix = "",
                this.headerInput = [{
                    name: '',
                    value: ''
                }],
                this.bodyInputs = [
                    {
                        name: '',
                        value: ''
                    }],
                this.formDataInputs = [
                    {
                        name: '',
                        value: ''
                    }],
                this.queryinputs = [
                    {
                        name: '',
                        value: ''
                    }
                ],
                this.tokenEndpoint.url = "",
                this.tokenEndpoint.queryParams = "false",
                this.tokenEndpoint.verbId = "",
                this.tokenEndpoint.webhookSchedulerAPIs = "",
                this.apiEndpoint.url = "",
                this.apiEndpoint.queryParams = "false",
                this.apiEndpoint.verbId = "POST",
                this.apiEndpoint.webhookSchedulerAPIs = "",
                this.errors.splice(0, this.errors.length),
                this.showTokenError = false,
                this.showErrorApi = false
        },
        //validateForm
        validateForm() {
            this.errors.splice(0, this.errors.length)
            // debugger;
            if (!this.formData.category || this.formData.category === '') {
                this.errors.push("Please select category");
            }else{
                if(this.formData.category && !this.categoryList.includes(this.formData.category)){
                    this.errors.push("Please enter a valid category");
                }
            }
            if (this.formData.credentialOrgType === '') {
                this.errors.push("Please select credential organization Type")
            }else{
                if (!["P", "C", "M", "T", "p", "c", "m", "t"].includes(this.formData.credentialOrgType)){
                    this.errors.push("Please enter a valid credential organization Type " + this.formData.credentialOrgType)
                }
            }
            // if (this.formData.credentialOrgType === "C" ) {
            //     if(this.formData.consumerCode === ''){
            //         this.errors.push("Please Select consumer code");
            //     }
            // }
            // if(this.formData.consumerCode && this.formData.consumerCode != ''){
            //     const consumer_ids_list = this.consumer.map(item => item.id);
            //     if (!consumer_ids_list.includes(this.formData.consumerCode)){
            //         this.errors.push("Please enter a valid consumer code");
            //     }
            // }
            // if(this.formData.manufacturerCode && this.formData.manufacturerCode != ''){
            //     const consumer_ids_list = this.manufacturer.map(item => item.id);
            //     if (!consumer_ids_list.includes(this.formData.manufacturerCode)){
            //         this.errors.push("Please enter a valid manufacturer code");
            //     }
            // }
            // if(this.formData.apiProviderOrg && this.formData.apiProviderOrg != ''){
            //     const api_provider_ids_list = this.apiProvider.map(item => item.id);
            //     if (!api_provider_ids_list.includes(this.formData.apiProviderOrg)){
            //         this.errors.push("Please enter a valid api provider code");
            //     }
            // }
            if(this.formData.productGroupId && this.formData.productGroupId != ''){
                const product_group_ids_list = this.useGroupData.map(item => item.id);
                // console.log("product_group_ids_list-->>", product_group_ids_list, " and this.formData.productGroupId->", this.formData.productGroupId);
                if (!product_group_ids_list.includes(this.formData.productGroupId)){
                    this.errors.push("Please enter a valid product group");
                }
            }
            // if(this.formData.productId && this.formData.productId != ''){
            //     // console.log("this.productList-->>",this.productList);
            //     if (!this.productList){
            //         this.errors.push("Please enter a valid product");
            //     }else{
            //         if (!this.productList.includes(this.formData.productId)){
            //             this.errors.push("Please enter a valid product");
            //         }
            //     }
                
            // }
            if (this.formData.credentialOrgType === "M" || this.formData.credentialOrgType === "m") {
                if (this.formData.manufacturerCode === ''){
                    this.errors.push("Please Select manufacturer code");
                }
            }
            
            if (this.formData.authenticationType === "tokenAuth" && this.tokenEndpoint.url === '') {
                this.errors.push("Please add token end point url code")
            }else{
                if(this.formData.authenticationType === "tokenAuth" && this.tokenEndpoint && this.tokenEndpoint.url){
                    const apis_ids_list = this.api_list.map(item => item.apiEndpointUrl);
                    if (!apis_ids_list.includes(this.tokenEndpoint.url)){
                        this.errors.push("Please enter valid tokenend point url")
                    }  
                }
            } 
            if (this.formData.authenticationType === "tokenAuth" && this.tokenEndpoint.verbId === '') {
                this.errors.push("Please add api method for Token Endpoint")
            }
            if (this.formData.tokenValidity === "") {
                this.errors.push("Please add token validity")
            }
            if (this.formData.serviceName === '') {
                this.errors.push("Please add service name")
            }
            if (this.formData.authenticationType === '') {
                this.errors.push("Please add authentication type")
            }
            //console.log("this.showErrorApi-->>", this.showErrorApi);
            //console.log("this.tokenEndpoint.url-->>", this.tokenEndpoint.url);
            if (this.showErrorApi) {
                this.errors.push("Please enter valid api endpoint url")
            }
            if (this.showTokenError) {
                this.errors.push("Please enter valid token endpoint url")
            }
            //console.log("this.apiEndpoint.url-->>", this.apiEndpoint);
            if(this.apiEndpoint && this.apiEndpoint.url===""){
                this.errors.push("Please enter valid api endpoint url")
            }else{
                if(this.apiEndpoint){
                    const apis_ids_list = this.api_list.map(item => item.apiEndpointUrl);
                    if (!apis_ids_list.includes(this.apiEndpoint.url)){
                        this.errors.push("Please enter valid api endpoint url")
                    }  
                }
            } 
        },
        //validate apiEndpoint url
        validateApiUrl() {
            var expression = /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;
            var regex = new RegExp(expression);
            if (this.apiEndpoint.url.match(regex)) {
                this.showErrorApi = false;
            }
            else {
                this.showErrorApi = true;
            }
        },
        //validatetokenurl
        validateTokenUrl() {
            this.handleapiEndpointChange(this.tokenEndpoint.url, "token");
            var expression = /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;
            var regex = new RegExp(expression);
            if (this.tokenEndpoint.url.match(regex)) {
                this.showTokenError = false;
            }
            else {
                this.showTokenError = true;
            }
        },
        //setProductList
        setProductList() {
            const ProductGroup = this.useGroupData.find((item)=>item.id === this.formData.productGroupId)
            this.productList = this.manufacturerDetails[ProductGroup.name];

        },
        //remote method search
        remoteMethod(query: string) {
            const test = query;
        },
        //get MCPT Data
        getMCPT() {
            ApiService.get(`/organization?organizationTypes=M,C,P,T`)
                .then((data) => {

                    this.useMCPTData = (JSON.parse(JSON.stringify(data.data.data)))
                    this.manufacturer = this.useMCPTData.filter((item) => item.organizationType === "Manufacturer")
                    this.apiProvider = JSON.parse(JSON.stringify(this.useMCPTData));
                    this.consumer = this.useMCPTData.filter((item) => item.organizationType === "Consumer");

                })
                .catch((error) => {
                    console.error("Error in MCPT", error)
                })
        },

        //getMasterData
        getMasterData() {
            ApiService.get("/master")
                .then((data) => {

                    this.useServiceData = (JSON.parse(JSON.stringify(data.data.data.serviceName)))
                    this.useOrganizationType = (JSON.parse(JSON.stringify(data.data.data.organizationType)))
                })
                .catch((error) => {
                    console.log("Error", error)
                })
        },

        //Get GroupData
        getGroup() {
            ApiService.get("/api/group")
                .then((data) => {
                    this.useGroupData = (JSON.parse(JSON.stringify(data.data.data)));
                })
                .catch((error) => {

                    console.log("Error in GroupApi", error)
                })
        },
        fetchManufacturerDetails() {
            this.handleOrganizationChange(this.formData.manufacturerCode)
            ApiService.get(`/product?manufacturerId=${this.formData.manufacturerCode}`)
                .then((data) => {
                    this.manufacturerDetails = { ...data.data.data.Insurance, ...data.data.data.WealthManagement }
                })
                .catch((error) => {
                    console.log("Error", error)
                })
        },

        submitForm() {
            this.validateForm();
            const body = this.objectMaker(this.bodyInputs);
            const header = this.objectMaker(this.headerInput, true);
            const queryParam = this.objectMaker(this.queryinputs);
            const formdata = this.objectMaker(this.formDataInputs);
            const postReqObject = {
                ...this.formData,
                credentials: {
                    prefix: this.formData.prefix,
                    body: {
                        ...body
                    },
                    header: {
                        ...header
                    },
                    form_data: {
                        ...formdata
                    },
                    query_params: {
                        ...queryParam
                    }
                },
                tokenEndpoint: {
                    ...this.tokenEndpoint
                },
                apiEndpoint: {
                    ...this.apiEndpoint
                }
            }

            if (this.errors.length === 0 && !this.showTokenError &&
                !this.showErrorApi) {
                ApiService.post("/cms/configuration", postReqObject as any)
                    .then((data) => {
                        this.removeFields();
                        hideModal(this.onBoardManufacturerModalRef);
                        ElNotification({
                            title: 'Success',
                            message: 'New Credential added!',
                            type: 'success',
                            duration: 2000,
                            position: 'top-right'
                        })
                    })
                    .catch((error) => {
                        console.log(error)
                        ElNotification({
                            title: 'Error',
                            message: JSON.stringify(error.response.data['errors'][0]['errorMessage']),
                            type: 'error',
                            duration: 2000,
                            position: 'top-right'
                        })
                    });
            }

        },
        objectMaker(arr, value_stringify=false) {
            const result = arr.reduce(function (r, e) {
                if (e.name != "" || e.value != "") {
                    if (value_stringify){
                        if(typeof e.value !=='string'){
                            r[e.name] = JSON.stringify(e.value);
                        }
                    }
                    r[e.name] = e.value;
                    return r;
                }
                return {};
            }, {});
            return result;
        },
        addbody(index) {
            this.bodyInputs.push({ name: '', value: "" });
        },
        removebody(index) {
            this.bodyInputs.splice(index, 1);
        },
        addformData(index) {
            this.formDataInputs.push({ name: '', value: "" });
        },
        removeformData(index) {
            this.formDataInputs.splice(index, 1);
        },
        addQueryData() {
            this.queryinputs.push({ name: '', value: "" });
        },
        removeQueryData(index) {
            this.queryinputs.splice(index, 1);
        },
        addHeaderData(index) {
            this.headerInput.push({ name: '', value: '' })
        },
        removeHeaderData(index) {
            this.headerInput.splice(index, 1);
        },
    },
    created() {
        // this.fetchManufacturerDetails();
        this.getMasterData2();
        this.getGroup();
        this.getMasterData();
        this.getMCPT();
    }
});
